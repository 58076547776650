import React ,{useEffect} from 'react'
import { useParams } from 'react-router-dom'
import categoryData from '../Content/CategoryData'
import './Outfit.css'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Outfit = () => {
  const { categoryId, itemId } = useParams()
  const category = categoryData[categoryId]
  const item = category.items.find((item) => item.id === itemId)

useEffect(() => {
  ;(window.adsbygoogle = window.adsbygoogle || []).push({})
}, [])


  if (!item) {
    return <div>Item not found</div>
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const isSpecialCategory =
    (categoryId === 'c' || categoryId === 'e') && item.gender === 'Female'
  const topwearText = isSpecialCategory ? 'Outfit' : 'Topwear'
  const bottomwearText = isSpecialCategory ? 'Accessory' : 'Bottomwear'

  return (
    <>
      <Navbar />
      <div className="outfit-page">
        <div className="outfit-ad">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3774650311756533"
            crossOrigin="anonymous"
          ></script>
          <ins
            class="adsbygoogle"
            style={{
              display: 'block',
            }}
            data-ad-client="ca-pub-3774650311756533"
            data-ad-slot="8094828071"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
        <div className="outfit-slider">
          <Slider {...settings}>
            <div>
              <img src={item.pic2} alt={`Item ${item.id}`} />
            </div>
            <div>
              <img src={item.pic3} alt={`Item ${item.id}`} />
            </div>
            <div>
              <img src={item.pic} alt={`Item ${item.id}`} />
            </div>
            <div>
              <img src={item.pic1} alt={`Item ${item.id}`} />
            </div>
          </Slider>
        </div>
        <div className="outfit-details">
          <p className="outfit-price">₹{item.price}*</p>
          <hr className="outfit-divider" />
          <div className="outfit-topdown">
            <h4>{topwearText}</h4>
            <h4>₹{item.price1}*</h4>
          </div>
          <a
            href={item.link1}
            target="_blank"
            rel="noopener noreferrer"
            className="outfit-link"
          >
            {item.link1}
          </a>
          <div className="outfit-topdown">
            <h4>{bottomwearText}</h4>
            <h4>₹{item.price2}*</h4>
          </div>
          <a
            href={item.link2}
            target="_blank"
            rel="noopener noreferrer"
            className="outfit-link"
          >
            {item.link2}
          </a>
          <p className="outfit-warning">* Prices can be different right now.</p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Outfit
